import React, { useState, useEffect } from "react";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { ResetForm } from "~/components/AccountPages";
import { activateAccount } from "~/helpers/requests/customer-requests";
import Cookies from "js-cookie";
import { css } from "styled-components";

const ResetPage = ({ location }) => {
  const [authErrorMessage, setAuthErrorMessage] = useState("");
  const [customerID, setCustomerID] = useState("");
  const [activationUrl, setActivationUrl] = useState("");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setActivationUrl(params.get("activation_url"));
  }, [location.search]);
  const submitForm = (values) => {
    activateAccount(activationUrl, values.password).then((res) => {
      const { customerActivateByUrl } = res.data.data;

      if (!customerActivateByUrl.customerUserErrors.length) {
        const {
          accessToken,
          expiresAt
        } = customerActivateByUrl.customerAccessToken;

        const expiryDate = new Date(expiresAt);
        Cookies.set("KEPT_SESS", accessToken, { expires: expiryDate });
        window.location.href = "/shop";
      } else {
        setAuthErrorMessage(customerActivateByUrl.customerUserErrors[0]);
      }
    });
  };
  return (
    <div
      css={css`
        min-height: calc(100vh - 123px - 150px);
        h1 {
          text-align: center;
          margin: 1rem auto 2rem;
        }
      `}
    >
      <Criteo />
      <SEO title="Activate Account" />
      <DeriveHeaderClasses location={location} />
      <div className="container text-center">
        <div className="row no-gutters pt-50 collection-header">
          <h1 className="ma">Activate Account</h1>
        </div>
      </div>
      <div className="error">{authErrorMessage}</div>
      <ResetForm submitForm={submitForm} authErrorMessage={authErrorMessage} />
    </div>
  );
};

export default ResetPage;
